import * as React from "react"
import AwesomeSlider from 'react-awesome-slider';
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import AwsSliderStyles from 'react-awesome-slider/dist/styles.css';
import notebookImage from '../../assets/images/notebook.png'
const AutoplaySlider = withAutoplay(AwesomeSlider);

export default function() {
    return <AutoplaySlider interval={30000}
                           play={false}
                           organicArrows={false}
                           cssModule={AwsSliderStyles}>
                <div>
                    <div className="image-left">
                      <h1>Be Curious about your Data</h1>
                      <h6>
                      Datareporter can help with data-driven decisions through the power of data analytics. 
                      Explore your data interactively with a point and click intuitive interface.
                      </h6>
                      {/* <a href="#">Demo</a> */}
                    </div>
                    <div className="image-right">
                      <img src={notebookImage} alt="Note book"/>
                    </div>
                </div>
                <div>
                    <div className="image-left">
                      <h1>Reports and Dashboards</h1>
                      <h6>
                        Create powerful reports and dashboards with your findings. Share and collaborate on reports withing your organisation. 
                      </h6>
                      <a href="#">See Examples</a>
                    </div>
                    <div className="image-right">
                      <img src={notebookImage} alt="Note book"/>
                    </div>
                </div>
                <div>
                    <div className="image-left">
                      <h1>Serverless and Open Source.</h1>
                      <h6>
                        Pay only for the infrastructure you use with the Google CloudRun. One click install in the Google Cloud. 
                        Available as a Kubernetes as well.
                      </h6>
                      <a href="#">One Click Install</a>
                    </div>
                    <div className="image-right">
                      <img src={notebookImage} alt="Note book"/>
                    </div>
                </div>
           </AutoplaySlider>;
}
