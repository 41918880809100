import * as React from "react";
import Header from '../componets/Template/Header';
import HeaderPage from '../componets/MainPage/Header';
import Slider from '../componets/MainPage/Slider';
import Article from '../componets/MainPage/Article';
import Footer from '../componets/MainPage/Footer';

import '../assets/sass/main.scss'

// markup
const IndexPage = () => {
  return (
    <main>
      <Header title={'Data reporter'}/>
      <header className="header-main-page">
        <div className="center-section">
          <div className="center-padding">
            <HeaderPage />
            <div className="clear"></div>
            <Slider />
          </div>
        </div>
      </header>
      <Article />
      <Footer />
    </main>
  )
}

export default IndexPage
