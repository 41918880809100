import * as React from "react"
import PriceBox from "./PriceBox";
import FooterBottom from "./FooterBottom";
export default function() {
    return <footer>
              <div className="clear"></div>
              <div className="footer-white">
              </div>
              <div className="footer-dark">
                <div className="clear"></div>
                <div className="center-section footer-feedback">
                  <div className="center-padding">
                    <FooterBottom />
                  </div>
                </div>
              </div>
           </footer>;
}
