import React, { useState, useEffect } from "react"
import logo from '../../assets/images/logo.png'
import close from '../../assets/images/close-menu.png'
import menu from '../../assets/images/menu.png'

export default function() {
    const [mobileMenu, setMobileMenu] = useState(false);

    const toggleMenu = () => {
      setMobileMenu(!mobileMenu);
    }

    useEffect(() => {
      const anchors = document.querySelectorAll('a[href*="#"]')

      for (let anchor of anchors) {
        anchor.addEventListener('click', function (e) {
          e.preventDefault()

          const blockID = anchor.getAttribute('href').substr(1)

          document.getElementById(blockID).scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        })
      }
    }, [])


    return <nav className="header-top">
                <a href="/"><img className="logo" src={logo} alt="Datareporter"/></a>
                <ul className="nav-desktop">
                  <li>
                    <a href="#datareporter" className="active">
                      Technology
                    </a>
                  </li>
                  <li>
                    <a href="#resources">
                      Resources
                    </a>
                  </li>
                  {/* <li>
                    <a href="#demo">
                      Demo
                    </a>
                  </li> */}
                  <li>
                    <a href="#contact" >
                      Contact
                    </a>
                  </li>
                </ul>
                <ul className="nav-mobile-menu is-mobile">
                  <li>
                    <img src={menu} onClick={toggleMenu} alt="Open menu"/>
                  </li>
                </ul>
                <div className={'nav-mobile ' + (mobileMenu ? 'open' : '')} >
                  <img className="logo" src={logo} alt="DATA Reporter"/>
                  <img className="close-menu" src={close} alt="close" onClick={toggleMenu}/>
                  <h4>Menu</h4>
                  <ul>
                    <li>
                      <a href="#datareporter">
                        Technology
                      </a>
                    </li>
                    <li>
                      <a href="#resources">
                        Resources
                      </a>
                    </li>
                    <li>
                      <a href="#security">
                        Data Security
                      </a>
                    </li>
                    {/* <li>
                      <a href="#damoe">
                        Demo
                      </a>
                    </li> */}
                    <li>
                      <a href="#contact" >
                        Contact
                      </a>
                    </li>
                  </ul>
                  {/* <a href="#" className="button">Demo</a> */}
                </div>
           </nav>;
}
